dmx.BaseComponent = dmx.createClass({

    constructor: function(node, parent) {
        this.$node = node;
        this.parent = parent;
        this.bindings = {};
        this.propBindings = {};
        this.children = [];
        this.listeners = {};
        
        this._prevProps = {};
        this._updatedProps = new Set();
        
        this.updatedProps = new Map();
        this.updateRequested = false;

        const self = this;
        this.props = new Proxy({}, {
            set (target, prop, value, receiver) {
                const oldValue = Reflect.get(target, prop, receiver);
                const ok = Reflect.set(target, prop, value, receiver);
                if (ok && oldValue !== value) {
                    //console.log(`Props Update ${self.name} (${prop}: ${oldValue}:${typeof oldValue} => ${value}:${typeof value})`);
                    self._updatedProps.add(prop);
                    self.requestUpdate(prop, oldValue);
                    dmx.requestUpdate(prop);
                }
                return ok;
            }
        });

        this.data = new Proxy({}, {
            signals: Object.create(null),

            get (target, prop, receiver) {
                const value = Reflect.get(target, prop, receiver);

                if (!this.signals[prop]) {
                    this.signals[prop] = dmx.signal(value);
                }

                return this.signals[prop].value;
            },

            set (target, prop, value, receiver) {
                const ok = Reflect.set(target, prop, value, receiver);

                if (!this.signals[prop]) {
                    this.signals[prop] = dmx.signal(value);
                } else {
                    this.signals[prop].value = value;
                }

                return ok;
            }
        });
        this.seed = Math.random();

        this.name = node.getAttribute('id') || node.getAttribute('name') || this.type.toLowerCase().replace(/^dmx-/, '');
        this.name = this.name.replace(/[^\w]/g, '');
        this.dmxDomId = node.getAttribute('dmxDomId');

        try {
            this.$initialData();
            this.$parseAttributes(node);
            this.render(node);
            if (this.$node) {
                this.$customAttributes('mounted', this.$node);
                if (this.dmxDomId) {
                    // Restore dmxDomId for Wappler
                    this.$node.setAttribute('dmxDomId', this.dmxDomId);
                }
                this.$node.dmxComponent = this;
                this.$node.dmxRendered = true;
            }
        } catch (e) {
            console.error(e);
        }
    },

    tag: null,
    initialData: {},
    attributes: {},
    methods: {},
    events: {
        destroy: Event
    },

    render: function(node) {
        if (this.$node) {
            this.$parse();
        }
    },

    // find component based on name inside children
    find: function(name) {
        if (this.name == name) return this;

        for (var i = 0; i < this.children.length; i++) {
            var found = this.children[i].find(name);
            if (found) return found;
        }

        return null;
    },

    // internal method for Wappler
    __find: function(dmxDomId) {
        if (this.dmxDomId == dmxDomId) return this;

        for (var i = 0; i < this.children.length; i++) {
            var found = this.children[i].__find(dmxDomId);
            if (found) return found;
        }

        return null;
    },

    // internal method for Wappler
    __replace: function(dmxDomId) {
        var child = this.__find(dmxDomId);

        if (child) {
            child.$destroy();

            var node = document.querySelector('[dmxDomId="' + dmxDomId + '"]');
            if (node) {
                var index = child.parent.children.indexOf(child);
                var Component = dmx.__components[child.data.$type];

                if (index > -1 && Component) {
                    var component = new Component(node, child.parent);
                    child.parent.children.splice(index, 1, component);
                    if (component.name) {
                        child.parent.add(component.name, component.data);
                    }
                }
            }

            dmx.requestUpdate();
        }
    },

    // internal method for Wappler
    __remove: function(dmxDomId) {
        var child = this.__find(dmxDomId);

        if (child) {
            child.$destroy();

            var index = child.parent.children.indexOf(this);
            if (index > -1) {
                child.parent.children.splice(index, 1);
            }

            dmx.requestUpdate();
        }
    },

    beforeUpdate: dmx.noop,
    update: dmx.noop,
    updated: dmx.noop,

    beforeDestroy: dmx.noop,
    destroyed: dmx.noop,

    addEventListener: function(type, callback) {
        if (!(type in this.listeners)) {
            this.listeners[type] = new Set();
        }
        this.listeners[type].add(callback);
    },

    removeEventListener: function(type, callback) {
        if (!(type in this.listeners)) return;
        this.listeners[type].delete(callback);
    },

    dispatchEvent: function(event, props, data, nsp) {
        if (typeof event == 'string') {
            var ComponentEvent = this.events[event] || CustomEvent;
            event = new ComponentEvent(event, props);
        }

        if (!(event.type in this.listeners)) return true;

        event.nsp = nsp;
        event.target = this;
        event.$data = data || {};
        for (let listener of this.listeners[event.type]) {
            if (listener.call(this, event) === false) {
                event.preventDefault();
            }
        }

        return !event.defaultPrevented;
    },

    $addChild: function(name, node) {
        var Component = dmx.__components[name];
        var component = new Component(node, this);
        this.children.push(component);
        if (component.name) {
            if (this.data[component.name] && dmx.debug) {
                console.warn('Duplicate name "' + component.name + '" found, component not added to scope.');
                //return;
            }
            this.set(component.name, component.data);
        }
    },

    $customAttributes: function(hook, node) {
        dmx.dom.getAttributes(node).forEach(attr => {
            if (dmx.__attributes[hook][attr.name]) {
                node.removeAttribute(attr.fullName);
                dmx.__attributes[hook][attr.name].call(this, node, attr);
            }
        });
    },

    $parse: function(node) {
        node = node || this.$node;

        if (!node) return;

        if (node.nodeType === 3) {
            if (dmx.reExpression.test(node.nodeValue)) {
                var nodeValue = node.nodeValue;

                if (nodeValue.substr(0, 2) == '{{' && nodeValue.substr(-2) == '}}' && nodeValue.indexOf('{{', 2) == -1) {
                    nodeValue = nodeValue.substring(2, nodeValue.length - 2);
                }

                this.$addBinding(nodeValue, value => {
                    node.nodeValue = value
                });
            }

            return;
        }

        if (node.nodeType !== 1) return;

        if (dmx.config.mapping) {
            Object.keys(dmx.config.mapping).forEach(map => {
                dmx.array(node.querySelectorAll(map)).forEach(node => {
                    if (!node.hasAttribute('is')) {
                        node.setAttribute('is', 'dmx-' + dmx.config.mapping[map]);
                    }
                });
            });
        }

        dmx.dom.walk(node, function(node) {
            if (node == this.$node) {
                // skip current node
                return;
            }

            // Element Node
            if (node.nodeType === 1) {
                var tagName = node.tagName.toLowerCase();
                var attributes = dmx.dom.getAttributes(node);

                if (node.hasAttribute('is')) {
                    tagName = node.getAttribute('is');
                }

                if (dmx.reIgnoreElement.test(tagName)) {
                    // ignore element
                    return false;
                }

                this.$customAttributes('before', node);
                var idx = attributes.findIndex(attr => attr.name === 'repeat');
                if (idx !== -1) return false;

                if (dmx.rePrefixed.test(tagName)) {
                    tagName = tagName.replace(/^dmx-/i, '');

                    if (tagName in dmx.__components) {
                        node.isComponent = true;
                        if (!node.dmxRendered) {
                          this.$addChild(tagName, node);
                        } else if (window.__WAPPLER__) {
                            // This breaks some components in design view
                            // causes flows to trigger constantly
                            // components ofter have there own parsing and this breaks it
                            if (node.dmxComponent && node.dmxComponent.$parse) {
                                // for now ignode specific for flows with script tag
                                if (!dmx.reIgnoreElement.test(node.tagName)) {
                                    node.dmxComponent.$parse();
                                }
                            }
                        }
                        return false;
                    } else {
                        console.warn('Unknown component found! ' + tagName);
                        return;
                    }
                }

                this.$customAttributes('mounted', node);
            }

            // Text Node
            if (node.nodeType === 3) {
                if (dmx.reExpression.test(node.nodeValue)) {
                    var nodeValue = node.nodeValue;

                    if (nodeValue.substr(0, 2) == '{{' && nodeValue.substr(-2) == '}}' && nodeValue.indexOf('{{', 2) == -1) {
                        nodeValue = nodeValue.substring(2, nodeValue.length - 2);
                    }
    
                    this.$addBinding(nodeValue, value => {
                        node.nodeValue = value
                    });
                }
            }
        }, this);
    },

    $update: function(idents) {
        try {
            if (this.beforeUpdate(idents) !== false) {
                try {
                    this.update(this._prevProps, this._updatedProps);
                } catch (e) {
                    console.error(e);
                }

                this.children.forEach(child => { child.$update(idents) });

                this.updated();

                this._prevProps = dmx.clone(this.props);
                this._updatedProps.clear();
            }
        } catch (e) {
            console.error(e);
        }
    },

    $parseAttributes: function(node) {
        var self = this;

        if (this.attributes) {
            Object.keys(this.attributes).forEach(function(prop) {
                //console.log('PARSE ATTRIBUTE', this.name, prop);
                var options = self.attributes[prop];
                var value = options.default;

                if (node.hasAttribute(prop)) {
                    if (options.type == Boolean) {
                        value = true;
                    } else {
                        value = node.getAttribute(prop);

                        if (options.type == Number) {
                            // Only set number is a valid number is given
                            if (value && !isNaN(Number(value))) {
                                value = Number(value);
                            }
                        }

                        if (options.type == String) {
                            value = String(value);
                        }

                        if (options.validate && !options.validate(value)) {
                            value = options.default;
                        }
                    }

                    node.removeAttribute(prop);
                }

                if (node.hasAttribute('dmx-bind:' + prop)) {
                    const expression = node.getAttribute('dmx-bind:' + prop);
                    const callback = this.$propBinding(prop).bind(this);

                    /*this.propBindings[prop] = {
                        expression: expression,
                        callback: callback,
                        idents: dmx.getIdents(expression),
                        value: null
                    };*/

                    this.$addBinding(expression, callback);

                    node.removeAttribute('dmx-bind:' + prop);
                } else {
                    self.props[prop] = dmx.clone(value);
                }
            }, this);
        }

        if (this.events) {
            Object.keys(this.events).forEach(function(event) {
                if (node.hasAttribute('on' + event)) {
                    //self.addEventListener(event, Function('event', node.getAttribute('on' + event)));
                    dmx.eventListener(self, event, Function('event', node.getAttribute('on' + event)), {});
                    node.removeAttribute('on' + event);
                }
            });
        }

        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (attr.name == 'on' && this.events[attr.argument]) {
                dmx.eventListener(self, attr.argument, function(event) {
                    if (event.originalEvent) {
                        event = event.originalEvent;
                    }

                    var returnValue = dmx.parse(attr.value, dmx.DataScope({
                        $event: event.$data,
                        $originalEvent: event
                    }, self));

                    return returnValue;
                }, attr.modifiers);

                node.removeAttribute(attr.fullName);
            }
        }, this);
    },

    requestUpdate: function(prop, oldValue) {
        //console.log(`request Update ${this.name} (${prop}: ${oldValue} => ${this.prop})`);
        if (!this.performUpdate) return;

        if (!this.updatedProps.has(prop)) {
            this.updatedProps.set(prop, oldValue);
        }

        if (!this.updateRequested) {
            //console.log('queue Microtask', this.name, this.updateRequested);
            queueMicrotask(() => {
                //console.log('exec Microtask', this.name, this.updateRequested);
                this.updateRequested = false;
                this.performUpdate(this.updatedProps);
                this.updatedProps.clear();
            });
        }

        this.updateRequested = true;
    },

    $propBinding: function(prop) {
        var options = this.attributes[prop];
        var self = this;

        return function(value) {
            if (value === undefined) {
                value = options.default;
            }
            
            if (options.type == Boolean) {
                value = !!value;
            }
            
            if (value != null) {
                if (options.type == Number) {
                    if (value !== '' && !isNaN(Number(value))) {
                        value = Number(value);
                    } else {
                        value = options.default;
                    }
                }

                if (options.type == String) {
                    value = String(value);
                }
            }
            
            if (options.validate && !options.validate(value)) {
                value = options.default;
            }
            
            self.props[prop] = dmx.clone(value);
        };
    },

    $initialData: function() {
        Object.assign(
            this.data,
            { $type: this.type },
            typeof this.initialData == 'function' ? this.initialData() : this.initialData
        );

        Object.keys(this.methods).forEach(function(method) {
            var self = this;
            this.data['__' + method] = function() {
                return self.methods[method].apply(self, Array.prototype.slice.call(arguments, 1));
            };
        }, this);
    },

    $addBinding: function(expression, cb) {
        if (!this.effects) this.effects = [];
        this.effects.push(dmx.effect(() => {
            cb.call(this, dmx.parse(expression, this));
        }));
    },

    $destroy: function() {
        this.dispatchEvent('destroy');
        this.beforeDestroy();
        this.$destroyChildren();
        if (this.parent) {
            this.parent.del(this.name);
        }
        if (this.$node) {
            dmx.dom.remove(this.$node);
        }
        if (this.effects) {
            this.effects.forEach(effect => {
                effect();
            });
            this.effects = null;
        }
        this.$node = null;
        this.parent = null;
        this.data = null;
        this.destroyed();
    },

    $destroyChildren: function() {
        this.children.forEach(child => { child.$destroy() });
        this.children = [];
    },

    get: function(name, ignoreParents) {
        if (this.data[name] !== undefined) {
            return this.data[name];
        }

        if (this.parent && ignoreParents !== true) {
            if (name == 'parent') {
                return this.parent.data;
            }

            return this.parent.get(name);
        }

        return null;
    },

    add: function(name, value) {
        if (this.data[name]) {
            if (Array.isArray(this.data[name])) {
                this.data[name].push(value);
            } else {
                this.data[name] = [this.data[name], value];
            }
        } else {
            this.set(name, value);
        }
    },

    set: function(name, value) {
        if (typeof name == 'object') {
            for (var prop in name) {
                if (name.hasOwnProperty(prop)) {
                    this.set(prop, name[prop]);
                }
            }
            return;
        }

        if (!dmx.equal(this.data[name], value)) {
            this.data[name] = value;
        }
    },

    del: function(name) {
        delete this.data[name];
    }
});
