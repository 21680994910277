dmx.Component('repeat-item', {

    constructor: function(fragment, parent, data, name) {
        this.parent = parent;
        this.bindings = {};
        this.propBindings = {};
        this.children = [];
        this.listeners = [];

        this._prevProps = {};
        this._updatedProps = new Set();

        this.data = new Proxy({}, {
            signals: Object.create(null),

            get(target, prop, receiver) {
                const value = Reflect.get(target, prop, receiver);

                if (!this.signals[prop]) {
                    this.signals[prop] = dmx.signal(value);
                }

                return this.signals[prop].value;
            },

            set(target, prop, value, receiver) {
                const ok = Reflect.set(target, prop, value, receiver);

                if (!this.signals[prop]) {
                    this.signals[prop] = dmx.signal(value);
                } else {
                    this.signals[prop].value = value;
                }

                return ok;
            }
        });

        Object.assign(this.data, data || {});
        this.seed = parent.seed;
        this.name = name || 'repeatItem';
        this.$nodes = [];
        for (var i = 0; i < fragment.childNodes.length; i++) {
            this.$nodes.push(fragment.childNodes[i]);
        }
    },

    beforeDestroy: function() {
        for (var i = 0; i < this.$nodes.length; i++) {
            var event = new Event('remove', { cancelable: true });
            if (this.$nodes[i].dispatchEvent(event)) {
                dmx.dom.remove(this.$nodes[i]);
                delete this.$nodes[i].dmxComponent;
            }
        }
    }

});
