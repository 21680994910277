(() => {

  class Scope {

    constructor (initialData = {}, parent = null) {
      if (typeof initialData !== 'object') {
        initialData = { $value: initialData };
      }

      this.data = new Proxy({}, {
        signals: Object.create(null),

        get(target, prop, receiver) {
            const value = Reflect.get(target, prop, receiver);

            if (!this.signals[prop]) {
                this.signals[prop] = dmx.signal(value);
            }

            return this.signals[prop].value;
        },

        set(target, prop, value, receiver) {
            const ok = Reflect.set(target, prop, value, receiver);

            if (!this.signals[prop]) {
                this.signals[prop] = dmx.signal(value);
            } else {
                this.signals[prop].value = value;
            }

            return ok;
        }
      });
      Object.assign(this.data, initialData);
      this.parent = parent;
      this.seed = Math.random();
    }

    get (name) {
      if (this.data[name]) {
        return this.data[name];
      }

      if (this.parent) {
        if (name == 'parent') {
          return this.parent.data;
        }

        return this.parent.get(name);
      }

      return null;
    }

    set (name, value) {
      if (typeof name === 'object') {
        for (const prop in name) {
          if (name.hasOwnProperty(prop)) {
            this.set(prop, name[prop]);
          }
        }
      } else {
        this.data[name] = value;
      }
    }

    has (name) {
      return this.data.hasOwnProperty(name);
    }

    del (name) {
      delete this.data[name];
    }

  }

  dmx.global = new Scope();
  dmx.DataScope = (data, parent) => {
    return new Scope(data, parent || dmx.global);
  };

})();